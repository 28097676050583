#map-container {
  background-color: #e8eef4;
  height: 350px;
  width: 100%;
  border-radius: 0 0 8px 8px;
}

/* TOOLTIP - BEGIN */
.leaflet-tooltip {
  padding: 0 !important;
  border-radius: 6px !important;
  font-size: 14px;
  color: #000;
  border: 1px solid #dddddd !important;
}
.leaflet-tooltip .header {
  background-color: #eceff1;
  padding: 4px 12px;
  border-radius: 6px 6px 0 0;
  border-bottom: 1px solid #dddddd;
}
.leaflet-tooltip .body {
  padding: 8px 14px;
}
.leaflet-tooltip .body .legend-color {
  width: 12px;
  height: 12px;
  background-color: #fafafa;
  border-radius: 50%;
}
/* TOOLTIP - END */

/* LEGEND - BEGIN */
#custom-map-legend {
  color: white;
  margin: 12px;
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
#custom-map-legend .gradient-color {
  width: 200px;
  height: 15px;
  background-color: grey;
  border-radius: 2px;
}
/* LEGEND - END */

.leaflet-control-attribution {
  display: none;
}

path.leaflet-interactive {
  outline: none;
}
path.disabled-feature {
  cursor: grab;
}

.leaflet-control,
.leaflet-pane,
.leaflet-top,
.leaflet-bottom {
  z-index: 0 !important;
}
